import adminRootInstance from "../utils/config/axios-config";

export const createServiceProvider = async (values) => {
  try {
    const response = await adminRootInstance.post(
      "/auth/business-request/public",
      values
    );
    if (response?.data?.message) {
      return {
        success: true,
        data: response?.data?.message,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
