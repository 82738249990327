import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ResidentViewPage from "../pages/Resident/View";
import ResidentEditPage from "../pages/Resident/Edit";
import BuySellEditPage from "../pages/BuySell/Edit";
import BuySellViewPage from "../pages/BuySell/View";
import ProtectedRoute from "./ProtectedRoute";
import Loader from "../components/Common/Loader";
import NotFound from "../components/Common/NotFound";
import ServiceProviderListPage from "../pages/ServiceProvider/List";
import ServiceProviderCreatePage from "../pages/ServiceProvider/Create";
import ServiceProviderEditPage from "../pages/ServiceProvider/Edit";
import ServiceProviderViewPage from "../pages/ServiceProvider/View";
import CreateServiceProvider from "../pages/CreateServiceProvider/Create";
import Login from "../components/Auth/Login";
import Residents from "../pages/Resident/List";
import ReportsList from "../pages/Reports/ReportsList";
import BuySell from "../pages/BuySell/List";
import ReviewManagement from "../pages/ReviewManagement/List";
import Dashboard from "../pages/Dashboard/Dashboard";
import Home from "../components/Home/Home";
import ResidentEngagementPage from "../pages/Reports/ResidentEngagement";
import BuySellPage from "../pages/Reports/BuySell";
import WordCloudPage from "../pages/Reports/WordCloud";
import ServiceProviderEngagementPage from "../pages/Reports/ServiceProviderEngagement";
import PublicReviewView from "../components/ServiceProviderReview/review";
import ServiceProviderTermsAndConditions from "../components/service-provider-terms-and-condition/ServiceProviderTermsAndConditions";
import ServiceProviderFlagsPage from "../pages/ServiceProvider/ServiceProviderFlagsPage";
import BuySellListingPage from "../pages/Reports/BuySellListingPage";
import ResidentReviewsPage from "../pages/Reports/ResidentReviews";
import ServiceProviderReviewsReportPage from "../pages/Reports/ServiceProviderReviewsReportPage";
import ServiceProviderFlagsReportPage from "../pages/Reports/ServiceProviderFlagsReportPage";
import BuySellFlagsPage from "../pages/BuySell/BuySellFlagsPage";
import { useDispatch, useSelector } from "react-redux";
import { loginHandler } from "../redux/Slices/AuthSlice";
import ServiceProviderViewAcknowlegedTerms from "../components/service-provider-terms-and-condition/ServiceProviderViewAcknowlegedTerms";

const RoutesList = () => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");

  const isAuthenticated = useSelector((state) => {
    if (accessToken && !state.auth.isAuthenticated) {
      let userName = localStorage.getItem("userName");
      dispatch(loginHandler(userName));
    }
    return accessToken || state.auth.isAuthenticated;
  });

  return (
    <Routes>
      <Route path={`/`} element={<Navigate to="/admin/login" />} />
      <Route
        path={`/admin`}
        element={
          isAuthenticated ? (
            <Navigate to="/home" />
          ) : (
            <Navigate to="/admin/login" />
          )
        }
      />
      <Route
        path={`/admin/resident`}
        element={<Navigate to="/admin/resident/all" />}
      />
      <Route
        path={`/admin/service-provider`}
        element={<Navigate to="/admin/service-provider/all" />}
      />
      <Route
        path={`/admin/review-management`}
        element={<Navigate to="/admin/review-management/all" />}
      />
      <Route
        path={`/admin/buy-sell`}
        element={<Navigate to="/admin/buy-sell/all" />}
      />
      <Route
        path={`/admin/reports`}
        element={<Navigate to="/admin/reports/all" />}
      />
      <Route path="/service">
        <Route
          path={`review/:token`}
          element={
            <Suspense fallback={<Loader />}>
              <PublicReviewView />
            </Suspense>
          }
        ></Route>
        <Route
          path={`terms/:token`}
          element={
            <Suspense fallback={<Loader />}>
              <ServiceProviderTermsAndConditions />
            </Suspense>
          }
        ></Route>
        <Route
          path={`terms/view/:token`}
          element={
            <Suspense fallback={<Loader />}>
              <ServiceProviderViewAcknowlegedTerms />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path={`/admin`}>
        <Route
          path={`login`}
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path={`home`}
          element={
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path={`dashboard`}
          element={
            <ProtectedRoute>
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            </ProtectedRoute>
          }
        ></Route>
        <Route path="resident">
          <Route
            path={`all`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <Residents />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`view/:id`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ResidentViewPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`edit/:id`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ResidentEditPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
        </Route>
        <Route path="service-provider">
          <Route
            path={`all`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ServiceProviderListPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`create`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ServiceProviderCreatePage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`view/:id`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ServiceProviderViewPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`edit/:id`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ServiceProviderEditPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`flags/:id`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ServiceProviderFlagsPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
        </Route>
        <Route path="review-management">
          <Route
            path={`all`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ReviewManagement />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
        </Route>
        <Route path="buy-sell">
          <Route
            path={`all`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <BuySell />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`view/:id`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <BuySellViewPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`edit/:id`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <BuySellEditPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`flags/:id`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <BuySellFlagsPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
        </Route>
        <Route path="reports">
          <Route
            path={`all`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ReportsList />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`resident-engagement`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ResidentEngagementPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route path={`buy-sell`}>
            <Route
              path=""
              element={
                <ProtectedRoute>
                  <Suspense fallback={<Loader />}>
                    <BuySellPage />
                  </Suspense>
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path={`listing/:id`}
              element={
                <ProtectedRoute>
                  <Suspense fallback={<Loader />}>
                    <BuySellListingPage />
                  </Suspense>
                </ProtectedRoute>
              }
            ></Route>
          </Route>
          <Route
            path={`resident-reviews/:residentId`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <ResidentReviewsPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`buy-sell`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <BuySellPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path={`word-cloud`}
            element={
              <ProtectedRoute>
                <Suspense fallback={<Loader />}>
                  <WordCloudPage />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
          <Route path={`service-provider-engagement`}>
            <Route
              path={""}
              element={
                <ProtectedRoute>
                  <Suspense fallback={<Loader />}>
                    <ServiceProviderEngagementPage />
                  </Suspense>
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path={`reviews/:id`}
              element={
                <ProtectedRoute>
                  <Suspense fallback={<Loader />}>
                    <ServiceProviderReviewsReportPage />
                  </Suspense>
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path={`flags/:id`}
              element={
                <ProtectedRoute>
                  <Suspense fallback={<Loader />}>
                    <ServiceProviderFlagsReportPage />
                  </Suspense>
                </ProtectedRoute>
              }
            ></Route>
          </Route>
        </Route>
        <Route
          path="public/business/registration"
          element={
            <Suspense fallback={<Loader />}>
              <CreateServiceProvider />
            </Suspense>
          }
        ></Route>
      </Route>
      <Route
        path={`*`}
        element={
          <Suspense fallback={<Loader />}>
            <NotFound />
          </Suspense>
        }
      ></Route>
    </Routes>
  );
};

export default RoutesList;
