import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import WordCloud from "react-d3-cloud";
import {
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Backdrop,
  Stack,
  Breadcrumbs,
  Typography,
  Button,
} from "@mui/material";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  BLACK,
  DEEP_OCEAN_BLUE,
  FLEX_END,
  SMALL,
  START,
} from "../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { fetchWordCloudReport } from "../../services/reports";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { reportsDatePickerStyle } from "../Common/CommonStyles";
import loader from "../../assets/images/Loader.gif";
import {
  formatDate,
  fromDateHandler,
  toDateHandler,
} from "../../helpers/DatePickerValues";
import { wordCloudCsvReport } from "../../services/csvReport";

const WordCloudFunction = () => {
  const initialFilterValues = {
    from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
    to: moment(dayjs().$d).format("DD-MM-YYYY"),
  };
  const [searchTerms, setSearchTerms] = useState([]);
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [searchValue, setSearchValue] = useState("");
  const filterValuesRef = useRef(filterValues);
  const searchValuesRef = useRef(searchValue);
  const dispatch = useDispatch();
  const isInitialRender = useRef(true);
  const MAX_FONT_SIZE = 200;
  const MIN_FONT_SIZE = 30;
  const MAX_FONT_WEIGHT = 700;
  const MIN_FONT_WEIGHT = 400;
  const MAX_WORDS = 150;

  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/reports/all"}
      className="breadcrumb__link"
    >
      Reports
    </Link>,
    <Typography key="3" color={BLACK}>
      Word Cloud
    </Typography>,
  ];

  useEffect(() => {
    filterValuesRef.current = filterValues;
  }, [filterValues]);

  useEffect(() => {
    searchValuesRef.current = searchValue;
  }, [searchValue]);

  const fetchData = useCallback(
    async (pageVal) => {
      const fromDateArr = filterValuesRef.current.from.split("-");
      const toDateArr = filterValuesRef.current.to.split("-");
      const formattedFromDate = `${fromDateArr[2]}-${fromDateArr[1]}-${fromDateArr[0]}`;
      const formattedToDate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[0]}`;
      dispatch(startLoading());
      const response = await fetchWordCloudReport(
        searchValuesRef.current,
        formattedFromDate,
        formattedToDate
      );
      if (response.success && response.data) {
        setSearchTerms(response?.data);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, filterValues]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const fetchSearchTerms = async () => {
      dispatch(startLoading());
      const fromDateArr = filterValuesRef.current.from.split("-");
      const toDateArr = filterValuesRef.current.to.split("-");
      const formattedFromDate = `${fromDateArr[2]}-${fromDateArr[1]}-${fromDateArr[0]}`;
      const formattedToDate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[0]}`;
      const response = await fetchWordCloudReport(
        searchValue,
        formattedFromDate,
        formattedToDate
      );
      if (response.success && response.data) {
        setSearchTerms(response?.data);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    const debouncer = setTimeout(() => {
      fetchSearchTerms();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchValue, dispatch]);

  const sortedWords = useMemo(
    () => searchTerms.sort((a, b) => b.value - a.value).slice(0, MAX_WORDS),
    [searchTerms]
  );

  const [minOccurences, maxOccurences] = useMemo(() => {
    const min = Math.min(...sortedWords.map((w) => w.value));
    const max = Math.max(...sortedWords.map((w) => w.value));
    return [min, max];
  }, [sortedWords]);

  const calculateFontSize = useCallback(
    (wordOccurrences) => {
      const normalizedValue =
        (wordOccurrences - minOccurences) / (maxOccurences - minOccurences);
      const fontSize =
        MIN_FONT_SIZE + normalizedValue * (MAX_FONT_SIZE - MIN_FONT_SIZE);
      return Math.round(fontSize);
    },
    [maxOccurences, minOccurences]
  );

  const calculateFontWeight = useCallback(
    (wordOccurrences) => {
      const normalizedValue =
        (wordOccurrences - minOccurences) / (maxOccurences - minOccurences);
      const fontWeight =
        MIN_FONT_WEIGHT + normalizedValue * (MAX_FONT_WEIGHT - MIN_FONT_WEIGHT);
      return Math.round(fontWeight);
    },
    [maxOccurences, minOccurences]
  );

  const clearHandler = () => {
    setFilterValues(() => ({
      from: moment(dayjs().subtract(30, "day").$d).format("DD-MM-YYYY"),
      to: moment(dayjs().$d).format("DD-MM-YYYY"),
    }));
    setSearchValue("");
  };

  const downloadHandler = async () => {
    const fromDateArr = filterValuesRef.current.from.split("-");
    const toDateArr = filterValuesRef.current.to.split("-");
    const formattedFromDate = `${fromDateArr[2]}-${fromDateArr[1]}-${fromDateArr[0]}`;
    const formattedToDate = `${toDateArr[2]}-${toDateArr[1]}-${toDateArr[0]}`;
    const response = await wordCloudCsvReport(
      searchValue,
      formattedFromDate,
      formattedToDate
    );
    if (response.data) {
      const fileName = "Word-cloud-report.csv";
      const file = new Blob([response.data], { type: "application/csv" });
      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    } else {
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  const formattedFromDate = formatDate(filterValues.from);
  const formattedToDate = formatDate(filterValues.to);
  const loading = useSelector((state) => state.common.loading);

  return (
    <>
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          color={DEEP_OCEAN_BLUE}
          open={loading}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Backdrop>
      </div>
      <Paper className="container" elevation={4}>
        <Grid container spacing={2} paddingBottom={2}>
          <Grid item md={12}>
            <Stack spacing={3}>
              <Breadcrumbs
                separator={<NavigateNextIcon />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Grid>
          <Grid item md={6}>
            <OutlinedInput
              fullWidth
              placeholder="Search by title"
              size={SMALL}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value.trim())}
              startAdornment={
                <InputAdornment position={START}>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item md={6} marginTop={"-10px"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  sx={reportsDatePickerStyle}
                  label="From"
                  value={dayjs(formattedFromDate)}
                  disableFuture
                  onChange={(newFromValue) =>
                    fromDateHandler(newFromValue, setFilterValues)
                  }
                />
                <DatePicker
                  sx={reportsDatePickerStyle}
                  label="To"
                  value={dayjs(formattedToDate)}
                  minDate={dayjs(formattedFromDate)}
                  onChange={(newToValue) =>
                    toDateHandler(newToValue, setFilterValues)
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={FLEX_END} marginBottom={2}>
          <Grid md={2} item className="resident__clear__button">
            <Button
              variant="contained"
              className="submit__button"
              onClick={clearHandler}
            >
              Clear All
            </Button>
          </Grid>
          <Grid md={2} item className="resident__clear__button">
            <Button
              variant="contained"
              className="submit__button"
              onClick={downloadHandler}
            >
              Download CSV
            </Button>
          </Grid>
        </Grid>
        <div>
          <WordCloud
            padding={3}
            font="Times"
            spiral="archimedean"
            rotate={0}
            width={1800}
            height={1000}
            fontWeight={(word) => calculateFontWeight(word.value)}
            data={sortedWords}
            fontSize={(word) => calculateFontSize(word.value)}
            random={() => 0.5}
          />
        </div>
      </Paper>
    </>
  );
};

export default WordCloudFunction;
