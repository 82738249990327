import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Modal,
  Box,
  Divider,
  Avatar,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { closeOperationalHoursModal } from "../../redux/Slices/CommonSlice";
import "./ServiceProvider.scss";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";

const ServiceProviderOperationalInfo = (props) => {
  const { onOkHandler, onCancelHandler, hoursValue } = props;
  const open = useSelector((store) => store.common.operationalHoursModalOpen);
  const dispatch = useDispatch();
  const [is24by7, setIs24by7] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const daysOfWeek = useMemo(
    () => [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    []
  );

  const initialDaysState = daysOfWeek.reduce((acc, day) => {
    acc[day] = { startTime: null, endTime: null };
    return acc;
  }, {});

  const [visibleDays, setVisibleDays] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = false;
      return acc;
    }, {})
  );
  const [days, setDays] = useState(initialDaysState);

  const toggleDayVisibility = (day) => {
    if (visibleDays[day] === true) {
      setDays((prev) => {
        const updatedDays = { ...prev };
        if (updatedDays[day]) {
          delete updatedDays[day];
        }
        return Object.keys(updatedDays).length === 0 ? {} : updatedDays;
      });
      if (selectedDays.length !== 0) {
        selectedDays.filter((val) => val !== day);
        setSelectedDays(selectedDays);
      }
    } else {
      const index = selectedDays.length !== 0 ? selectedDays.indexOf(day) : -1;
      if (index === -1) {
        selectedDays.push(day);
        setSelectedDays(selectedDays);
      }
    }
    setVisibleDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const handleTimeChange = (day, type, value) => {
    setDays((prev) => {
      const updatedDays = { ...prev };
      updatedDays[day] = {
        ...updatedDays[day],
        [type]: value ? dayjs(value) : null,
      };
      return updatedDays;
    });
  };

  const onInitDateFunction = useCallback(() => {
    if (hoursValue && hoursValue.length === 1 && hoursValue[0].day === "ALL") {
      setIs24by7(true);
    } else if (hoursValue && hoursValue?.length !== 0) {
      const updatedSchedule = daysOfWeek.reduce((acc, day) => {
        const matchingDay = hoursValue?.find((item) => item.day === day);
        acc[day] = matchingDay
          ? {
              startTime: matchingDay.startTime
                ? dayjs(matchingDay.startTime)
                : null,
              endTime: matchingDay.endTime ? dayjs(matchingDay.endTime) : null,
            }
          : { startTime: null, endTime: null };
        return acc;
      }, {});
      setDays(updatedSchedule);

      const updatedVisibleDays = daysOfWeek.reduce((acc, day) => {
        const matchingDay = hoursValue.find((item) => item.day === day);
        if (matchingDay) {
          setSelectedDays((prevState) => [...prevState, day]);
        }
        acc[day] = !!matchingDay;
        return acc;
      }, {});
      setVisibleDays(updatedVisibleDays);
    }
  }, [
    hoursValue,
    daysOfWeek,
    setIs24by7,
    setDays,
    setVisibleDays,
    setSelectedDays,
  ]);

  useEffect(() => {
    if (open) {
      console.log("calleeeddd", new Date());

      onInitDateFunction();
    }
  }, [open, onInitDateFunction]);

  const handleSubmit = () => {
    if (is24by7) {
      const updatedDays = { ALL: { startTime: null, endTime: null } };
      setDays(updatedDays);
    }
    Object.keys(days).forEach((key) => {
      const day = days[key];
      if (key !== "ALL" && day?.startTime === null && day?.endTime === null) {
        delete days[key];
      } else {
        // Use .utc() to prevent any local timezone conversion
        days[key] = {
          startTime: day.startTime
            ? dayjs(day.startTime).format("YYYY-MM-DDTHH:mm:ss")
            : null,
          endTime: day.endTime
            ? dayjs(day.endTime).format("YYYY-MM-DDTHH:mm:ss")
            : null,
        };
      }
    });
    const result = Object.entries(days).map(([day, times]) => ({
      day,
      startTime: times.startTime || null,
      endTime: times.endTime || null,
    }));

    onOkHandler(result);
    dispatch(closeOperationalHoursModal());
  };

  const copyTimes = () => {
    if (!selectedDays.length) return;
    let sourceStartTime = null;
    let sourceEndTime = null;

    for (const day of selectedDays) {
      if (days[day]?.startTime && sourceStartTime === null) {
        sourceStartTime = days[day].startTime;
      }
      if (days[day]?.endTime && sourceEndTime === null) {
        sourceEndTime = days[day].endTime;
      }
      if (sourceStartTime && sourceEndTime) break;
    }

    if (!sourceStartTime && !sourceEndTime) return;

    setDays((prevDays) => {
      const updatedDays = { ...prevDays };
      selectedDays.slice(1).forEach((targetDay) => {
        updatedDays[targetDay] = {
          startTime: sourceStartTime,
          endTime: sourceEndTime,
        };
      });
      return updatedDays;
    });
  };

  const handleSelectWeekdays = () => {
    const newDays = daysOfWeek.slice(0, 5);
    setSelectedDays(newDays);

    setVisibleDays((prev) => {
      const initialDays = prev || {};
      const updatedDays = Object.keys(initialDays).reduce((acc, key) => {
        acc[key] = newDays.includes(key) ? true : false;
        return acc;
      }, {});
      newDays.forEach((day) => {
        if (!(day in initialDays)) {
          updatedDays[day] = true;
        }
      });
      return updatedDays;
    });

    setDays(() =>
      newDays.reduce((acc, day) => {
        acc[day] = { startTime: null, endTime: null };
        return acc;
      }, {})
    );
  };

  const handleSelectWeekends = () => {
    const newDays = daysOfWeek.slice(5);
    setSelectedDays(newDays);

    setVisibleDays((prev) => {
      const initialDays = prev || {};
      const updatedDays = Object.keys(initialDays).reduce((acc, key) => {
        acc[key] = newDays.includes(key) ? true : false;
        return acc;
      }, {});

      newDays.forEach((day) => {
        if (!(day in initialDays)) {
          updatedDays[day] = true;
        }
      });

      return updatedDays;
    });

    setDays(() =>
      newDays.reduce((acc, day) => {
        acc[day] = { startTime: null, endTime: null };
        return acc;
      }, {})
    );
  };

  const handleSelectAll = () => {
    const newDays = daysOfWeek;
    setSelectedDays(newDays);

    setVisibleDays((prev) => {
      const initialDays = prev || {};
      const updatedDays = Object.keys(initialDays).reduce((acc, key) => {
        acc[key] = newDays.includes(key) ? true : false;
        return acc;
      }, {});

      newDays.forEach((day) => {
        if (!(day in initialDays)) {
          updatedDays[day] = true;
        }
      });

      return updatedDays;
    });

    setDays(() =>
      newDays.reduce((acc, day) => {
        acc[day] = { startTime: null, endTime: null };
        return acc;
      }, {})
    );
  };

  const cancelHandler = () => {
    onCancelHandler();
    dispatch(closeOperationalHoursModal());
    setDays({});
    setVisibleDays({});
    setIs24by7(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={cancelHandler}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "60%", md: "40%" },
          maxHeight: "90vh",
          overflowY: "auto",
          padding: "20px",
          borderRadius: "8px",
          bgcolor: "background.paper",
          boxShadow: 24,
        }}
      >
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography
              variant="h5"
              align="center"
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              Business Hours
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={is24by7}
                    onChange={() => setIs24by7(!is24by7)}
                    color="primary"
                  />
                }
                label="24/7"
              />
            </Grid>
            {!is24by7 && (
              <>
                <Stack direction="row" spacing={1} justifyContent="center">
                  {daysOfWeek.map((day) => (
                    <Avatar
                      key={day}
                      onClick={() => toggleDayVisibility(day)}
                      sx={{
                        bgcolor: visibleDays[day] ? "#003350" : "grey",
                        cursor: "pointer",
                      }}
                    >
                      {day.charAt(0).toUpperCase()}
                    </Avatar>
                  ))}
                </Stack>
                <Divider sx={{ my: 2 }} />
                {daysOfWeek.map(
                  (day) =>
                    visibleDays[day] && (
                      <Grid key={day} container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                          <TimePicker
                            label="Start Time"
                            value={
                              days[day]?.startTime
                                ? dayjs(days[day].startTime)
                                : null
                            }
                            onChange={(newValue) =>
                              handleTimeChange(day, "startTime", newValue)
                            }
                            renderInput={(params) => (
                              <TextField {...params} fullWidth size="small" />
                            )}
                            sx={{ width: "100%" }}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TimePicker
                            label={`${day} End Time`}
                            value={
                              days[day]?.endTime
                                ? dayjs(days[day].endTime)
                                : null
                            }
                            onChange={(newValue) =>
                              handleTimeChange(day, "endTime", newValue)
                            }
                            sx={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth size="small" />
                            )}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                          />
                        </Grid>
                      </Grid>
                    )
                )}
              </>
            )}

            <Grid container justifyContent="space-between" sx={{ mt: 3 }}>
              {!is24by7 && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={copyTimes}
                        disabled={!selectedDays.length}
                      >
                        Copy Times
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSelectAll}
                      >
                        Select All
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSelectWeekdays}
                      >
                        Weekdays
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleSelectWeekends}
                      >
                        Weekends
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                style={{
                  paddingTop: "20px",
                  alignItems: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={cancelHandler}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Save Schedule
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </>
      </Box>
    </Modal>
  );
};

export default ServiceProviderOperationalInfo;
