import {
  Badge,
  IconButton,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Tooltip,
  Grid,
} from "@mui/material";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import FlagIcon from "@mui/icons-material/Flag";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RecyclingIcon from "@mui/icons-material/Recycling";
import StarIcon from "@mui/icons-material/Star";
import sortInactiveIcon from "../../assets/images/sort_grey.png";
import sortAscIcon from "../../assets/images/asc_sort.png";
import sortDscIcon from "../../assets/images/dsc_sort.png";
import "./Common.scss";
import {
  CENTER,
  DEEP_OCEAN_BLUE,
  TOP,
  WHITE,
  RED,
  ROW,
  NO_WRAP,
  BUY_SELL,
  RESIDENT_LIST,
  REVIEW_LIST,
  SERVICE_PROVIDER_LIST,
  GOLD,
} from "../../utils/constants";

const DataTable = (props) => {
  const noBreakheaders = ["Phone Number", "Business Name"];

  const RatingStars = ({ value }) => {
    return (
      <Rating
        name="rating-stars"
        value={value}
        precision={0.5} // allow half star increments
        readOnly // make the rating read-only
      />
    );
  };
  const {
    from,
    headers,
    data,
    onView,
    onEdit,
    onDelete,
    sortOrderHandler,
    totalRecords,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClick,
    handleViewClick,
    sortBy,
    sortColumn,
    classes,
  } = props;

  return (
    <div style={{ position: "relative", overflowX: "auto" }}>
      <Table className="TableComp__table">
        <TableHead>
          <TableRow sx={{ whiteSpace: NO_WRAP }}>
            {headers.map(
              (header) =>
                header !== null && (
                  <TableCell
                    key={header?.label}
                    className={
                      header?.label === "Actions"
                        ? "TableComp__table__header__Actions"
                        : noBreakheaders.includes(header?.label)
                        ? "No_Break_TableComp__table__header"
                        : "TableComp__table__header"
                    }
                    width={
                      header?.width ? header?.width : `${100 / headers.length}%`
                    }
                    sx={{
                      ...(header.label === "Actions" && {
                        position: "sticky",
                        right: 0,
                        backgroundColor: "white",
                        zIndex: 1,
                      }),
                    }}
                  >
                    {header?.label !== "Actions" &&
                    header?.label !== "Status" &&
                    header?.label !== "Description" &&
                    header?.label !== "Condition" &&
                    header?.label !== "Delivery Type" &&
                    header?.label !== "Free" &&
                    (header?.label !== "Category" ||
                      from !== SERVICE_PROVIDER_LIST) ? (
                      <TableSortLabel
                        active={
                          header?.sortKey
                            ? sortColumn === header?.sortKey
                            : sortColumn === header?.label
                        }
                        direction={
                          sortColumn === header?.label ||
                          sortColumn === header?.sortKey
                            ? sortBy
                            : "asc"
                        }
                        onClick={() =>
                          sortOrderHandler(
                            header?.sortKey ? header?.sortKey : header?.label
                          )
                        }
                        IconComponent={() => (
                          <img
                            src={
                              (sortColumn === header?.label ||
                                sortColumn === header?.sortKey) &&
                              sortBy === "asc"
                                ? sortAscIcon
                                : (sortColumn === header?.label ||
                                    sortColumn === header?.sortKey) &&
                                  sortBy === "desc"
                                ? sortDscIcon
                                : sortInactiveIcon
                            }
                            alt="Sort icon"
                            width={10}
                            height={10}
                          />
                        )}
                      >
                        {header?.label}
                      </TableSortLabel>
                    ) : (
                      header?.label
                    )}
                  </TableCell>
                )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={CENTER} colSpan={headers?.length}>
                No records to show
              </TableCell>
            </TableRow>
          ) : (
            data?.map((row, i) => (
              <TableRow key={i}>
                {row?.commonColumns.map(
                  (data, index) =>
                    data !== null && (
                      <TableCell key={index} component="th" scope={ROW}>
                        <div
                          className={`${
                            index === 1
                              ? "Service_Provider_phone_number_TableComp__table__cell__Content"
                              : "TableComp__table__cell__Content"
                          }`}
                        >
                          <Tooltip title={data} placement={TOP} arrow>
                            <span
                              className={`desc-data ${
                                index <= 1 ? classes : ""
                              }`}
                              onClick={() =>
                                from === REVIEW_LIST
                                  ? handleViewClick(index, row)
                                  : ""
                              }
                            >
                              {data}
                            </span>
                          </Tooltip>
                          {from === REVIEW_LIST && row?.flag && index === 0 && (
                            <IconButton
                              onClick={(event) => handleClick(row?.data, event)}
                            >
                              <FlagIcon
                                sx={{
                                  color: RED,
                                }}
                              />
                            </IconButton>
                          )}
                          <Grid>
                            {from === SERVICE_PROVIDER_LIST &&
                              row?.data?.serviceProviderFlags.length >= 1 &&
                              index === 3 && (
                                <IconButton
                                  sx={{ marginRight: 5 }}
                                  onClick={(event) =>
                                    handleClick(row?.data, event)
                                  }
                                >
                                  <FlagIcon
                                    sx={{
                                      color: RED,
                                    }}
                                  />
                                </IconButton>
                              )}
                          </Grid>
                          <Grid>
                            {from === SERVICE_PROVIDER_LIST &&
                              index === 8 &&
                              row?.data?.rating !== "0" &&
                              row?.data?.rating !== "0.0" && (
                                <StarIcon
                                  sx={{ color: GOLD, marginRight: 7 }}
                                />
                              )}
                          </Grid>
                          {index === 0 &&
                            from === BUY_SELL &&
                            row?.data?.buySellItem?.sold && (
                              <Badge badgeContent={"S"}></Badge>
                            )}
                          {from === BUY_SELL &&
                            row?.data?.buySellItemFlagsCount > 0 &&
                            index === 3 && (
                              <IconButton
                                onClick={(event) =>
                                  handleClick(row?.data, event)
                                }
                                sx={{ marginRight: 5 }}
                              >
                                <FlagIcon
                                  sx={{
                                    color: RED,
                                  }}
                                />
                              </IconButton>
                            )}
                          {index === 1 &&
                            from === REVIEW_LIST &&
                            row?.data?.anonymous && (
                              <Badge badgeContent={"A"}></Badge>
                            )}
                          {index === 2 &&
                            from === REVIEW_LIST &&
                            row?.data?.modifiedByAdmin && (
                              <Badge
                                badgeContent={"M"}
                                sx={{
                                  "& .MuiBadge-badge": {
                                    padding: "0 4px",
                                  },
                                }}
                              ></Badge>
                            )}
                        </div>
                      </TableCell>
                    )
                )}
                {from === RESIDENT_LIST && (
                  <TableCell>
                    <Badge
                      badgeContent={row?.residentStatus}
                      className="TableComp__table__cell__badge"
                      sx={{
                        "& .MuiBadge-badge": {
                          color: WHITE,
                          backgroundColor: DEEP_OCEAN_BLUE,
                        },
                      }}
                    />
                  </TableCell>
                )}
                {from === REVIEW_LIST && (
                  <TableCell>
                    <RatingStars value={row?.ratingValue} />
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    position: "sticky",
                    right: 0,
                    backgroundColor: "white", // Adjust based on your design
                    zIndex: 1, // Ensure it’s above other elements
                    display: "flex", // Use flexbox
                    justifyContent: "flex-start", // Align items to the start
                    // gap: 1,
                  }}
                >
                  <IconButton
                    disabled={
                      row?.residentStatus === "Phone Number Verified" ||
                      row?.residentStatus === "Awaiting OTP" ||
                      (from === RESIDENT_LIST &&
                        row?.data?.deleted &&
                        row?.data?.phone === null)
                    }
                    onClick={() => onView(row?.data)}
                  >
                    <Tooltip title={"View"} placement={TOP} arrow>
                      <FindInPageIcon className="TableComp__table__icon" />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    disabled={
                      row?.residentStatus === "Phone Number Verified" ||
                      row?.residentStatus === "Awaiting OTP" ||
                      row?.data?.deleted ||
                      row?.data?.serviceProvider?.deleted ||
                      row?.data?.buySellItem?.deleted
                    }
                    onClick={() => onEdit(row?.data)}
                  >
                    <Tooltip title={"Edit"} placement={TOP} arrow>
                      <EditIcon className="TableComp__table__icon" />
                    </Tooltip>
                  </IconButton>
                  {from === REVIEW_LIST && row?.data?.deleted ? (
                    ""
                  ) : (
                    <IconButton
                      disabled={
                        from === RESIDENT_LIST &&
                        row?.data?.deleted &&
                        row?.data?.phone === null
                      }
                      onClick={() => onDelete(row?.data)}
                    >
                      {(from === RESIDENT_LIST && row?.data?.deleted) ||
                      (from === SERVICE_PROVIDER_LIST &&
                        row?.data?.serviceProvider?.deleted) ||
                      (from === BUY_SELL && row?.data?.buySellItem?.deleted) ? (
                        <Tooltip title={"Reinstate"} placement={TOP} arrow>
                          <RecyclingIcon className="TableComp__table__icon" />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={
                            from === SERVICE_PROVIDER_LIST ||
                            from === RESIDENT_LIST
                              ? "Suspend"
                              : "Delete"
                          }
                          placement={TOP}
                          arrow
                        >
                          <DeleteIcon className="TableComp__table__icon" />
                        </Tooltip>
                      )}
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default DataTable;
