import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmationModal,
  openDeleteReasonModal,
  startLoading,
  stopLoading,
} from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteServiceProvider,
  fetchServiceProvider,
} from "../../services/service-provider";
import loader from "../../assets/images/Loader.gif";
import ConfirmationModal from "../Common/ConfirmationModal";
import DeleteReasonModal from "../Common/DeleteReasonModal";
import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import {
  BLACK,
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  INHERIT,
  NONE,
  REINSTATE_MESSAGE,
  ROW,
  SERVICE_PROVIDER_VIEW,
  SPACE_BETWEEN,
  TOP,
} from "../../utils/constants";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

const View = () => {
  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/service-provider/all"}
      className="breadcrumb__link"
    >
      Service Provider
    </Link>,
    <Typography key="3" color={BLACK}>
      View
    </Typography>,
  ];

  const { id } = useParams();
  const dispatch = useDispatch();
  const [serviceProvider, setServiceProvider] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [scheduleMap, setScheduleMap] = useState([]);
  const navigate = useNavigate();

  const fetchScheduleMap = (serviceProviderServiceHours) => {
    if (
      serviceProviderServiceHours?.length === 1 &&
      serviceProviderServiceHours[0]?.day === "ALL"
    ) {
      setScheduleMap([
        {
          day: serviceProviderServiceHours[0]?.day,
          startTime: null,
          endTime: null,
        },
      ]);
    } else {
      const result = allDays.map((day) => {
        const daySchedule = allDays.find((item) => item.day === day.day);
        return {
          day: day,
          startTime: daySchedule ? formatTime(daySchedule.startTime) : null,
          endTime: daySchedule ? formatTime(daySchedule.endTime) : null,
        };
      });
      debugger;
      setScheduleMap(result);
    }
  };

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const response = await fetchServiceProvider(id);
      if (response.success && response.data) {
        setServiceProvider(response?.data);
        fetchScheduleMap(response?.data?.businessHours);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    })();
  }, [dispatch, id]);

  const onEdit = () => {
    navigate(`/admin/service-provider/edit/${id}`);
  };

  const onDelete = () => {
    if (serviceProvider?.serviceProvider?.deleted) {
      setConfirmationMessage(REINSTATE_MESSAGE);
      dispatch(openConfirmationModal());
    } else {
      dispatch(openDeleteReasonModal());
    }
  };

  const deleteHandler = async (values) => {
    dispatch(startLoading());
    try {
      const response = await deleteServiceProvider(id, values?.reason);

      let message = "Operation failed.";
      let variant = "error";
      navigate(`/admin/service-provider/all`);
      if (response.success && response.data) {
        message = response.data || "Operation successful.";
        variant = response.data?.data?.isDuplicatePhoneNumber
          ? "error"
          : "success";
      } else if (typeof response.data === "string") {
        message = response.data;
      }

      enqueueSnackbar(message, { variant });
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      dispatch(stopLoading());
    }
  };

  const formatTime = (dateTime) => {
    if (!dateTime) return "-";
    const date = new Date(dateTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${amPm}`;
  };

  const allDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const loading = useSelector((state) => state.common.loading);

  return (
    <>
      <div>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          color={DEEP_OCEAN_BLUE}
          open={loading}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Backdrop>
      </div>
      <Paper className="paper__container" elevation={4}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Stack spacing={3}>
              <Breadcrumbs
                separator={<NavigateNextIcon />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          </Grid>
          <Grid item md={3}>
            <Paper
              elevation={4}
              className="ResidentView__basic__details__container"
            >
              {serviceProvider?.image === null ? (
                <Box
                  border="1px solid rgba(0, 0, 0, 0.23)"
                  height={"100%"}
                  display={FLEX}
                  justifyContent={CENTER}
                  alignItems={CENTER}
                  padding={"6px"}
                  borderRadius={"4px"}
                >
                  <div
                    className="ServiceProviderCreate__image__picker__button"
                    style={{
                      pointerEvents: NONE,
                      color: INHERIT,
                    }}
                  >
                    <div>
                      <ImageNotSupportedIcon className="ServiceProviderCreate__image__picker__add__photo__icon" />
                      <Typography fontSize="1rem" textTransform={NONE}>
                        Image Not Uploaded
                      </Typography>
                    </div>
                  </div>
                </Box>
              ) : (
                <Box
                  border="1px solid rgba(0, 0, 0, 0.23)"
                  height={"100%"}
                  display={FLEX}
                  justifyContent={CENTER}
                  alignItems={CENTER}
                  padding={"6px"}
                  borderRadius={"4px"}
                >
                  <img
                    src={serviceProvider?.image}
                    alt=""
                    width={"100%"}
                    height={"100%"}
                  ></img>
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item md={9}>
            <Paper
              className="ResidentView__basic__details__container"
              elevation={4}
            >
              <Grid container spacing={1}>
                <Grid item md={12} display={FLEX} alignItems={CENTER}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Full Name
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    md={9}
                    paddingLeft={2}
                    display={FLEX}
                    alignItems={CENTER}
                  >
                    <Typography
                      fontWeight={600}
                      color={DEEP_OCEAN_BLUE}
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider
                        ? `${serviceProvider?.serviceProvider?.firstName} ${serviceProvider?.serviceProvider?.lastName}`
                        : "-"}
                    </Typography>
                    {serviceProvider?.serviceProvider?.resident && (
                      <Box component="span" className="view__modal__badge">
                        <Typography fontWeight={600}>R</Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid item display={FLEX} md={12}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Phone
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider?.phone &&
                      serviceProvider.serviceProvider.phone.length === 10
                        ? `${
                            serviceProvider.serviceProvider.countryCode
                          }-${serviceProvider.serviceProvider.phone.slice(
                            0,
                            5
                          )}-${serviceProvider.serviceProvider.phone.slice(5)}`
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Business Name
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider?.businessName || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Preferred Language
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider?.preferredLanguage
                        ?.name || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Address
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Typography
                      className={
                        serviceProvider?.serviceProvider ? "" : "data__opacity"
                      }
                    >
                      {serviceProvider?.serviceProvider?.address
                        ?.formattedAddress || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12} display={FLEX}>
                  <Grid display={FLEX} md={3} justifyContent={SPACE_BETWEEN}>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      Location
                    </Typography>
                    <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                      :
                    </Typography>
                  </Grid>
                  <Grid md={9} paddingLeft={2}>
                    <Link
                      key="1"
                      color={DEEP_OCEAN_BLUE}
                      to={
                        serviceProvider?.serviceProvider?.address?.locationUrl
                      }
                      target="_blank"
                      className="ServiceProviderView__location__link"
                    >
                      {serviceProvider?.serviceProvider?.address?.locationUrl}
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={3}>
              <Grid item md={12} paddingBottom={1}>
                <div className="ResidentView__section__divider">
                  <span className="ResidentView__section__divider__span">
                    Other Information
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Email
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    serviceProvider?.serviceProvider ? "" : "data__opacity"
                  }
                >
                  {serviceProvider?.serviceProvider?.email || "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Name Prefix
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    serviceProvider?.serviceProvider ? "" : "data__opacity"
                  }
                >
                  {serviceProvider?.serviceProvider?.namePrefix || "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Name Suffix
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    serviceProvider?.serviceProvider ? "" : "data__opacity"
                  }
                >
                  {serviceProvider?.serviceProvider?.nameSuffix || "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Title
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    serviceProvider?.serviceProvider ? "" : "data__opacity"
                  }
                >
                  {serviceProvider?.serviceProvider?.title || "-"}
                </Typography>
              </Grid>
              <Grid item md={4} display={FLEX} justifyContent={SPACE_BETWEEN}>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Serviced To
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Tooltip
                  title={
                    serviceProvider?.servicedToList
                      ?.map(
                        (resident) =>
                          resident?.resident.firstName +
                          " " +
                          resident?.resident.lastName
                      )
                      ?.join(", ") || "-"
                  }
                  placement={TOP}
                  arrow
                >
                  <Typography
                    className={
                      serviceProvider?.serviceProvider ? "" : "data__opacity"
                    }
                  >
                    {serviceProvider?.servicedToList
                      ?.map(
                        (resident) =>
                          resident?.resident.firstName +
                          " " +
                          resident?.resident.lastName
                      )
                      ?.join(", ") || "-"}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid
                item
                md={4}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Joined on
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    serviceProvider?.serviceProvider ? "" : "data__opacity"
                  }
                >
                  {serviceProvider?.serviceProvider?.createdDate
                    ? moment(
                        serviceProvider?.serviceProvider?.createdDate
                      ).format("Do MMMM YYYY")
                    : "-"}
                </Typography>
              </Grid>
              <Grid item md={4} display={FLEX} justifyContent={SPACE_BETWEEN}>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Category
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Tooltip
                  title={
                    serviceProvider?.serviceProvider?.categories
                      ?.map((category) => category.name)
                      .join(", ") || "-"
                  }
                  placement={TOP}
                  arrow
                >
                  <Typography
                    className={
                      serviceProvider?.serviceProvider?.categories?.length > 0
                        ? ""
                        : "data__opacity"
                    }
                  >
                    {serviceProvider?.serviceProvider?.categories
                      ?.map((category) => category.name)
                      .join(", ") || "-"}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item md={4} display={FLEX} justifyContent={SPACE_BETWEEN}>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Description
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.description}
                </Typography>
              </Grid>
              <Grid item md={4} display={FLEX} justifyContent={SPACE_BETWEEN}>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Private Notes
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={8} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    serviceProvider?.serviceProvider ? "" : "data__opacity"
                  }
                >
                  {serviceProvider?.serviceProvider?.privateNotes || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={2}>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Door Delivery
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.deliverable ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Home Visit
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.serviceProvider?.homeVisit ? "Yes" : "No"}
                </Typography>
              </Grid>
              <Grid item md={5} display={FLEX} justifyContent={SPACE_BETWEEN}>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Referred By
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Tooltip
                  title={
                    serviceProvider?.referredByList
                      ?.map(
                        (resident) =>
                          resident?.resident.firstName +
                          " " +
                          resident?.resident.lastName
                      )
                      ?.join(", ") || "-"
                  }
                  placement={TOP}
                  arrow
                >
                  <Typography
                    className={
                      serviceProvider?.serviceProvider ? "" : "data__opacity"
                    }
                  >
                    {serviceProvider?.referredByList
                      ?.map(
                        (resident) =>
                          resident?.resident.firstName +
                          " " +
                          resident?.resident.lastName
                      )
                      ?.join(", ") || "-"}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Refer Anonymously
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.referredByList?.[0]?.anonymous
                    ? "Yes"
                    : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Referrer Availed Service
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography>
                  {serviceProvider?.referredByList?.[0]?.availedService
                    ? "Yes"
                    : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={CENTER}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Distance From Community
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    serviceProvider?.serviceProvider ? "" : "data__opacity"
                  }
                >
                  {serviceProvider?.serviceProvider?.address
                    ?.distanceFromCommunity
                    ? serviceProvider?.serviceProvider?.address
                        ?.distanceFromCommunity + " Km"
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={TOP}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Service Price Starts At
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    serviceProvider?.serviceProvider ? "" : "data__opacity"
                  }
                >
                  {serviceProvider?.serviceProvider?.serviceStartPrice || "-"}
                </Typography>
              </Grid>

              <Grid
                item
                md={5}
                display={FLEX}
                alignItems={TOP}
                justifyContent={SPACE_BETWEEN}
              >
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Service Price Upto
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={7} display={FLEX} alignItems={CENTER}>
                <Typography
                  className={
                    serviceProvider?.serviceProvider ? "" : "data__opacity"
                  }
                >
                  {serviceProvider?.serviceProvider?.serviceEndPrice || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {serviceProvider?.serviceProvider?.deletedReason && (
            <>
              <Grid item md={2} display={FLEX} justifyContent={SPACE_BETWEEN}>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  Deleted Reason
                </Typography>
                <Typography color={DEEP_OCEAN_BLUE} fontWeight={600}>
                  :
                </Typography>
              </Grid>
              <Grid item md={10}>
                <Typography>
                  {serviceProvider?.serviceProvider?.deletedReason}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <div className="ResidentView__section__divider">
              <span className="ResidentView__section__divider__span">
                Business Hours
              </span>
            </div>
          </Grid>
          <Grid item xs={4}>
            <Grid container className="schedule-card">
              {scheduleMap &&
              scheduleMap?.length === 1 &&
              scheduleMap[0]?.day === "ALL" ? (
                <Grid item xs={4}>
                  <Typography
                    color={BLACK}
                    fontWeight={600}
                    className="schedule-day"
                  >
                    Available 24/7
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {scheduleMap?.map(({ day, startTime, endTime }) => (
                      <React.Fragment key={day} className={"schedule-item"}>
                        <Grid item xs={4}>
                          <Typography
                            color={DEEP_OCEAN_BLUE}
                            fontWeight={600}
                            className="schedule-day"
                          >
                            {day}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            color={BLACK}
                            fontWeight={600}
                            className="schedule-time"
                          >
                            {`Start: ${startTime || "-"}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            color={BLACK}
                            fontWeight={600}
                            className="schedule-time"
                          >
                            {`End: ${endTime || "-"}`}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            display={FLEX}
            justifyContent={CENTER}
            marginTop={
              serviceProvider?.serviceProvider?.status === "Pending" ? 5 : 0
            }
          >
            <Stack spacing={3} direction={ROW}>
              {!serviceProvider?.serviceProvider?.deleted ? (
                <>
                  <Button
                    type="button"
                    variant="contained"
                    className="submit__button"
                    onClick={onEdit}
                  >
                    Edit
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    className="submit__button"
                    onClick={onDelete}
                  >
                    Suspend
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  className="submit__button"
                  onClick={onDelete}
                >
                  Reinstate
                </Button>
              )}
            </Stack>
            <ConfirmationModal
              message={confirmationMessage}
              onOkHandler={deleteHandler}
              onCancelHandler={() => {}}
              cancelText="No"
              okText="Yes"
            />
            <DeleteReasonModal
              onOkHandler={deleteHandler}
              onCancelHandler={() => {}}
              cancelText="Cancel"
              okText="Submit"
              from={SERVICE_PROVIDER_VIEW}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default View;
