import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import loader from "../../assets/images/Loader.gif";
import {
  fetchServiceProviderByToken,
  acceptTermsAndConditions,
} from "../../services/service-provider";
import {
  Container,
  Grid,
  Paper,
  Button,
  Typography,
  Box,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import "./ServiceProviderTermsAndConditions.scss";
import NivasiDarkIcon from "../../utils/images/nivasi-icon-dark.png";
import PrivacyAndTermsIcon from "../../utils/images/privacy-and-terms.png";
import ThankYouIcon from "../../utils/images/thankyou.png";
import NivasiLightIcon from "../../utils/images/nivasi-icon-light.png";
import FooterImage from "../../utils/images/footer.png";
import Modal from "@mui/material/Modal";
import { FLEX } from "../../utils/constants";
import axios from "axios";

const ServiceProviderTermsAndConditions = (props) => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [serviceProvider, setServiceProvider] = useState(null);
  const [serviceProviderName, setServiceProviderName] = useState("");
  const [thankYouModal, setThankYouModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [status, setStaus] = useState(null);
  const [spinning, setSpinning] = useState(true);
  const [language, setLanguage] = useState("English");
  const [ip, setIp] = useState("");

  useEffect(() => {
    // Fetch the IP address
    axios.get("https://api.ipify.org?format=json").then((response) => {
      setIp(response.data.ip);
    });
  }, []);

  useEffect(() => {
    const preferredLanguage = searchParams.get("preferredLanguage");
    if (
      preferredLanguage &&
      (preferredLanguage === "English" || preferredLanguage === "Tamil")
    ) {
      setLanguage(preferredLanguage);
    } else {
      setLanguage("English");
    }
  }, [searchParams]);

  const handleOpen = async () => {
    const response = await acceptTermsAndConditions(token, ip);
    if (response.success && response.data) {
      setTermsAccepted(true);
    }
    setThankYouModal(true);
  };

  const handleClose = () => setThankYouModal(false);

  const handleLanguage = (e) => {
    setLanguage(e.target.value);
  };

  const termsAndConditions = [
    {
      id: 1,
      title: "Introduction",
      content:
        "Welcome to Nivasi. These terms and conditions outline the rules and regulations you agree to abide by in order to be listed on the Nivasi app.",
    },
    {
      id: 2,
      title: "Definitions",
      content: (
        <>
          <strong> Service provider:</strong>{" "}
          <b>
            {" "}
            <b>
              <i>{serviceProviderName}</i>
            </b>
          </b>
          , the entity providing services to the community.
          <br />
          <strong>User:</strong> any individual or community resident using the
          services provided by the service provider.
          <br />
          <strong>Services: </strong> all services and products offered by the
          service provider to the community.
        </>
      ),
    },
    {
      id: 3,
      title: "Services provided",
      content: "You agree to offer the following services to the community:",
    },
    {
      id: 4,
      title: "Service provider responsibilities",
      content:
        "You must provide accurate and complete information about the services they provide to the community.\nYou may have access to contact information of residents you provide services to. You are responsible for maintaining the confidentiality of this information.\nYou must comply with all applicable laws and regulations when providing services within the community.\nYou will not engage in fraudulent or illegal activities.",
    },
    {
      id: 5,
      title: "Resident service provider",
      content:
        "If you are a resident of the community you are providing service to, you can showcase services offered by you on the app. However, these services should not be in violation of any association bye-laws. If they are found to be in violation, the company reserves the right to remove them from the app. In certain circumstances, the company may require written approval from the resident welfare association before allowing the resident service information to be listed on the app.",
    },
    {
      id: 6,
      title: "Account deletion",
      content:
        "You have the right to delete your service provider listing with Nivasi by writing an email to admin@nivasiapp.com. Your listing will be de-activated and not be visible to residents. When you request your account to be restored, it will be done at the sole discretion of the company.",
    },
    {
      id: 7,
      title: "Account termination",
      content:
        "We reserve the right to remove service providers that are found to be inactive, insufficiently engaged within the community or in violation of the terms and conditions.",
    },
    {
      id: 8,
      title: "Service availability",
      content:
        "You will make reasonable efforts to ensure that the services are available during normal business hours or the hours mentioned in your listing.",
    },
    {
      id: 9,
      title: "Limitation of liability",
      content:
        "The company will not be held liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the listing of your services on the app. The company's total liability to you for any claims arising out of or related to the services shall not exceed the amount paid by you to the company in the six months preceding the claim.",
    },
    {
      id: 10,
      title: "Indemnification",
      content:
        "You agree to indemnify and hold harmless the company, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, and expenses (including reasonable attorney's fees) arising out of or related to your listing on Nivasi.",
    },
    {
      id: 11,
      title: "Privacy",
      content:
        "You agree not to misuse the contact information of community residents.",
    },
    {
      id: 12,
      title: "Governing law",
      content:
        "These terms shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of India.",
    },
    {
      id: 13,
      title: "Amendments",
      content:
        "Nivasi reserves the right to amend these terms at any time. You will be notified of any changes by posting the updated terms on our website. Continued use of the services after any changes constitutes acceptance of the new terms.",
    },
    {
      id: 14,
      title: "Contact information",
      content: (
        <>
          If you have any questions or concerns about these terms, please
          contact us at:
          <br />
          Ekam Illam Private Limited
          <br />
          46 Fanepet 1st Street,Subbu Towers 3rd Floor, Nandanam, Chennai - 600
          035
          <br />
          info@nivasiapp.com
          <br />
           90920-90569
        </>
      ),
    },
  ];

  const termsAndConditionsTamil = [
    {
      id: 1,
      title: "அறிமுகம்",
      content:
        "நிவாசிக்கு வரவேற்கிறோம். இந்த விதிமுறைகள் மற்றும் நிபந்தனைகள் நிவாசி பயன்பாட்டில் பட்டியலிடப்படுவதற்கு நீங்கள் கடைப்பிடிக்க ஒப்புக்கொள்ளும் விதிகள் மற்றும் விதிமுறைகளை கோடிட்டுக் காட்டுகின்றன.",
    },
    {
      id: 2,
      title: "வரையறைகள்",
      content: (
        <>
          <strong> சேவை வழங்குநர்:</strong>{" "}
          <b>
            {" "}
            <b>
              <i>{serviceProviderName}</i>
            </b>
          </b>
          , சமூகத்திற்கு சேவைகளை வழங்கும் நிறுவனம்.
          <br />
          <strong>பயனர்:</strong> சேவை வழங்குநரால் வழங்கப்படும் சேவைகளைப்
          பயன்படுத்தும் எந்தவொரு தனிநபர் அல்லது சமூக குடியிருப்பாளர்.
          <br />
          <strong>சேவைகள்: </strong> சமூகத்திற்கு சேவை வழங்குநரால் வழங்கப்படும்
          அனைத்து சேவைகள் மற்றும் தயாரிப்புகள்.
        </>
      ),
    },
    {
      id: 3,
      title: "வழங்கப்படும் சேவைகள்",
      content: "சமூகத்திற்கு பின்வரும் சேவைகளை வழங்க ஒப்புக்கொள்கிறீர்கள்:",
    },
    {
      id: 4,
      title: "சேவை வழங்குநரின் பொறுப்புகள்",
      content:
        "சமூகத்திற்கு அவர்கள் வழங்கும் சேவைகள் பற்றிய துல்லியமான மற்றும் முழுமையான தகவலை நீங்கள் வழங்க வேண்டும்.\nநீங்கள் சேவைகளை வழங்கும் குடியிருப்பாளர்களின் தொடர்புத் தகவலை நீங்கள் அணுகலாம். இந்தத் தகவலின் இரகசியத்தன்மையைப் பேணுவதற்கு நீங்கள் பொறுப்பாவீர்கள்.\nசமூகத்திற்குள் சேவைகளை வழங்கும்போது நீங்கள் பொருந்தக்கூடிய அனைத்து சட்டங்கள் மற்றும் ஒழுங்குமுறைகளுக்கு இணங்க வேண்டும்.\nநீங்கள் மோசடி அல்லது சட்டவிரோத நடவடிக்கைகளில் ஈடுபட மாட்டீர்கள்.",
    },
    {
      id: 5,
      title: "குடியிருப்பாளர் மற்றும் சேவை வழங்குநர்",
      content:
        "நீங்கள் சேவை வழங்கும் சமூகத்தில் வசிப்பவராக இருந்தால், நீங்கள் வழங்கும் சேவைகளை ஆப்ஸில் காட்சிப்படுத்தலாம். எவ்வாறாயினும், இந்த சேவைகள் எந்தவொரு சங்க விதிகளையும் மீறுவதாக இருக்கக்கூடாது. அவை மீறப்பட்டதாகக் கண்டறியப்பட்டால், அவற்றை பயன்பாட்டிலிருந்து அகற்ற நிறுவனத்திற்கு உரிமை உண்டு. சில சூழ்நிலைகளில், குடியுரிமைச் சேவைத் தகவலை பயன்பாட்டில் பட்டியலிட அனுமதிக்கும் முன், நிறுவனத்திற்கு குடியுரிமைச் சங்கத்திடமிருந்து எழுத்துப்பூர்வ ஒப்புதல் தேவைப்படலாம்.",
    },
    {
      id: 6,
      title: "கணக்கு நீக்கம்",
      content:
        "admin@nivasiapp.com க்கு மின்னஞ்சலை எழுதுவதன் மூலம் நிவாசியுடன் உங்கள் சேவை வழங்குநர் பட்டியலை நீக்க உங்களுக்கு உரிமை உள்ளது. உங்கள் பட்டியல் செயலிழக்கப்படும் மற்றும் குடியிருப்பாளர்களால் பார்க்க முடியாது. உங்கள் கணக்கை மீட்டெடுக்கும்படி நீங்கள் கோரும்போது, ​​அது நிறுவனத்தின் முழு விருப்பத்தின் பேரில் செய்யப்படும்.",
    },
    {
      id: 7,
      title: "கணக்கு நிறுத்தம்",
      content:
        "சமூகத்தில் செயல்படாத, போதிய ஈடுபாடு இல்லாத அல்லது விதிமுறைகள் மற்றும் நிபந்தனைகளை மீறும் சேவை வழங்குநர்களை அகற்றுவதற்கான உரிமை எங்களுக்கு உள்ளது.",
    },
    {
      id: 8,
      title: "கிடைக்கும் சேவை",
      content:
        "வழக்கமான வணிக நேரங்கள் அல்லது உங்கள் பட்டியலில் குறிப்பிடப்பட்டுள்ள மணிநேரங்களில் சேவைகள் கிடைப்பதை உறுதிசெய்ய நீங்கள் நியாயமான முயற்சிகளை மேற்கொள்வீர்கள்.",
    },
    {
      id: 9,
      title: "பொறுப்பு வரம்பு",
      content:
        "பயன்பாட்டில் உங்கள் சேவைகளை பட்டியலிடுவதால் அல்லது அது தொடர்பாக ஏற்படும் நேரடி, மறைமுக, தற்செயலான, சிறப்பு அல்லது அதன் விளைவாக ஏற்படும் சேதங்களுக்கு நிறுவனம் பொறுப்பேற்காது. சேவைகளில் இருந்து எழும் அல்லது தொடர்புடைய எந்தவொரு கோரிக்கைகளுக்கும் நிறுவனத்தின் மொத்தப் பொறுப்பு, உரிமைகோரலுக்கு முந்தைய ஆறு மாதங்களில் நிறுவனத்திற்கு நீங்கள் செலுத்திய தொகையை விட அதிகமாக இருக்கக்கூடாது.",
    },
    {
      id: 10,
      title: "இழப்பீடு",
      content:
        "நிவாசியில் உங்கள் பட்டியலிலிருந்து எழும் அல்லது தொடர்புடைய எந்தவொரு உரிமைகோரல்கள், பொறுப்புகள், சேதங்கள் மற்றும் செலவுகள் (நியாயமான வழக்கறிஞர் கட்டணம் உட்பட) ஆகியவற்றிலிருந்து நிறுவனம், அதன் துணை நிறுவனங்கள் மற்றும் அந்தந்த அதிகாரிகள், இயக்குநர்கள், ஊழியர்கள் மற்றும் முகவர்களுக்கு இழப்பீடு வழங்கவும், பாதிப்பில்லாதவர்களாகவும் இருக்க ஒப்புக்கொள்கிறீர்கள்.",
    },
    {
      id: 11,
      title: "தனியுரிமை",
      content:
        "சமூக குடியிருப்பாளர்களின் தொடர்புத் தகவலை தவறாகப் பயன்படுத்த வேண்டாம் என்று ஒப்புக்கொள்கிறீர்கள்.",
    },
    {
      id: 12,
      title: "ஆளும் சட்டம்",
      content:
        "இந்த விதிமுறைகள் இந்திய சட்டங்களின்படி நிர்வகிக்கப்படும் மற்றும் கட்டமைக்கப்படும். இந்த விதிமுறைகளிலிருந்து அல்லது அது தொடர்பாக எழும் எந்தவொரு சர்ச்சைகளும் இந்திய நீதிமன்றங்களின் பிரத்யேக அதிகார வரம்பிற்கு உட்பட்டது.",
    },
    {
      id: 13,
      title: "திருத்தங்கள்",
      content:
        "இந்த விதிமுறைகளை எந்த நேரத்திலும் திருத்தும் உரிமை நிவாசிக்கு உள்ளது. எங்கள் இணையதளத்தில் புதுப்பிக்கப்பட்ட விதிமுறைகளை இடுகையிடுவதன் மூலம் ஏதேனும் மாற்றங்கள் இருந்தால் உங்களுக்குத் தெரிவிக்கப்படும். ஏதேனும் மாற்றங்களுக்குப் பிறகு சேவைகளைத் தொடர்ந்து பயன்படுத்துவது புதிய விதிமுறைகளை ஏற்றுக்கொள்வதைக் குறிக்கிறது.",
    },
    {
      id: 14,
      title: "தொடர்பு தகவல்",
      content: (
        <>
          இந்த விதிமுறைகள் குறித்து உங்களுக்கு ஏதேனும் கேள்விகள் அல்லது கவலைகள்
          இருந்தால், தயவுசெய்து எங்களை தொடர்பு கொள்ளவும்:
          <br />
          Ekam Illam Private Limited
          <br />
          46 Fanepet 1st Street,Subbu Towers 3rd Floor, Nandanam, Chennai - 600
          035
          <br />
          info@nivasiapp.com
          <br />
           90920-90569
        </>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const response = await fetchServiceProviderByToken(token);
      if (response.success && response.data) {
        const serviceProviderFromResponse = response?.data?.serviceProvider;
        setServiceProvider(serviceProviderFromResponse);
        setStaus(serviceProviderFromResponse.status);
        setServiceProviderName(serviceProviderFromResponse.name);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
      setSpinning(false);
    })();
  }, [dispatch, token, termsAccepted]);

  return (
    <React.Fragment>
      {!spinning ? (
        status === "Active" ? (
          <Paper
            className="container"
            elevation={4}
            sx={{ overflow: "auto" }}
            style={{ maxHeight: 750, overflow: "auto" }}
          >
            <Grid container spacing={2} padding={2}>
              <div className="container-fluid">
                <img
                  src={NivasiDarkIcon}
                  className="navbar-brand nivasi-icon"
                  alt="Nivasi Icon"
                />
              </div>
              <Container>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={7}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography variant="h4" className="privacy-terms-heading">
                      {serviceProviderName || "[service provider name]"},
                      <Typography className="thankyou-text">
                        Thank you!
                      </Typography>
                      <Typography>
                        We appreciate you for acknowledging and accepting our
                        terms and conditions.
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <img
                      src={PrivacyAndTermsIcon}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Paper>
        ) : status !== "Pending" ? (
          <Paper
            className="container"
            elevation={4}
            sx={{ overflow: "auto" }}
            style={{ maxHeight: 750, overflow: "auto" }}
          >
            <Grid container spacing={2} padding={2}>
              <div className="container-fluid">
                <img
                  src={NivasiDarkIcon}
                  className="navbar-brand nivasi-icon"
                  alt="Nivasi Icon"
                />
              </div>
              <Container>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={7}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography variant="h4" className="privacy-terms-heading">
                      <Typography className="thankyou-text">
                        Link Expired or Not Found
                      </Typography>
                      <Typography>
                        We're sorry, but the link you have used is either
                        expired or invalid. Please contact support if you need
                        further assistance.
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <img
                      src={PrivacyAndTermsIcon}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Paper>
        ) : (
          <Paper
            className="container"
            elevation={4}
            sx={{ overflow: "auto" }}
            style={{ maxHeight: 750, overflow: "auto" }}
          >
            <Grid container spacing={2} padding={2}>
              <div className="container-fluid">
                <img
                  src={NivasiDarkIcon}
                  className="navbar-brand nivasi-icon"
                  alt="Nivasi Icon"
                />
              </div>
              <FormControl fullWidth className="language-dropdown">
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={language}
                  onChange={handleLanguage}
                  label="Language"
                >
                  <MenuItem value={"English"}>English</MenuItem>
                  <MenuItem value={"Tamil"}>Tamil</MenuItem>
                </Select>
              </FormControl>
              <Container>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={9}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h4"
                      className={
                        language === "English"
                          ? "privacy-terms-heading"
                          : "privacy-terms-heading-tamil"
                      }
                    >
                      {language === "English"
                        ? "Terms and conditions for "
                        : "விதிமுறைகள் மற்றும் நிபந்தனைகள் "}
                      {serviceProviderName || "[service provider name]"}
                    </Typography>
                    <Typography className="privacy-terms-date">
                      {language === "English"
                        ? "Updated: "
                        : "புதுப்பிக்கப்பட்டது: "}
                      14 June 2024
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <img
                      src={PrivacyAndTermsIcon}
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Container>
              <Container>
                <Grid marginTop={5}>
                  <Grid>
                    <Typography fontWeight={"600"}>Business</Typography>
                  </Grid>
                  <Grid display={FLEX} paddingTop={"2px"}>
                    <Grid item md={3}>
                      <Typography fontWeight={"600"}>Name:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography paddingLeft={"25px"}>
                        {serviceProvider?.businessName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid display={FLEX}>
                    <Grid item md={3}>
                      <Typography fontWeight={"600"}>Phone:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography paddingLeft={"21px"}>
                        {serviceProvider?.phoneNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid display={FLEX}>
                    <Grid item md={3}>
                      <Typography fontWeight={"600"}>Delivery:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography paddingLeft={"10px"}>
                        {serviceProvider?.homeVisit
                          ? "Home Visit"
                          : "Door Delivery"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid display={FLEX}>
                    <Grid item md={3}>
                      <Typography fontWeight={"600"}>About:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography paddingLeft={"25px"}>
                        {serviceProvider?.aboutTheBusiness}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid display={FLEX}>
                    <Grid item md={3}>
                      <Typography fontWeight={"600"}>Location:</Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography paddingLeft={"7px"}>
                        {serviceProvider?.address}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  {language === "English"
                    ? termsAndConditions.map((term) => (
                        <React.Fragment key={term.id}>
                          <Typography
                            variant="h5"
                            className="privacy-terms-number"
                            style={{ paddingTop: "20px" }}
                          >
                            {term.id}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="privacy-terms-title"
                          >
                            {term.title}
                          </Typography>
                          <Typography className="privacy-terms-description">
                            {term.content}
                          </Typography>
                          {term.id === 3 && (
                            <Box
                              my={4}
                              display="flex"
                              alignItems="center"
                              gap={4}
                              p={2}
                            >
                              <List
                                sx={{
                                  width: "100%",
                                  maxWidth: 360,
                                  bgcolor: "background.paper",
                                  position: "relative",
                                  overflow: "auto",
                                  maxHeight: 300,
                                  "& ul": { padding: "2px 2px 5ox " },
                                }}
                                alignItems="center"
                              >
                                {serviceProvider?.categories?.map((item) => (
                                  <ListItem
                                    key={item}
                                    sx={{ listStyleType: "disc" }}
                                  >
                                    <ListItemText
                                      primary={item}
                                      sx={{ display: "list-item" }}
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </Box>
                          )}
                        </React.Fragment>
                      ))
                    : termsAndConditionsTamil.map((term) => (
                        <React.Fragment key={term.id}>
                          <Typography
                            variant="h5"
                            className="privacy-terms-number"
                            style={{ paddingTop: "20px" }}
                          >
                            {term.id}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="privacy-terms-title"
                          >
                            {term.title}
                          </Typography>
                          <Typography className="privacy-terms-description">
                            {term.content}
                          </Typography>
                          {term.id === 3 && (
                            <Box
                              my={4}
                              display="flex"
                              alignItems="center"
                              gap={4}
                              p={2}
                            >
                              <List
                                sx={{
                                  width: "100%",
                                  maxWidth: 360,
                                  bgcolor: "background.paper",
                                  position: "relative",
                                  overflow: "auto",
                                  maxHeight: 300,
                                  "& ul": { padding: "2px 2px 5ox " },
                                }}
                                alignItems="center"
                              >
                                {serviceProvider?.categories?.map((item) => (
                                  <ListItem
                                    key={item}
                                    sx={{ listStyleType: "disc" }}
                                  >
                                    <ListItemText
                                      primary={item}
                                      sx={{ display: "list-item" }}
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </Box>
                          )}
                        </React.Fragment>
                      ))}
                </Box>
                <Box
                  alignItems={"center"}
                  alignSelf={"center"}
                  alignContent={"center"}
                  paddingTop={"25px"}
                  textAlign={"center"}
                >
                  <Button
                    className="accept-button"
                    variant={"contained"}
                    onClick={handleOpen}
                    style={{ background: "black" }}
                  >
                    {language === "English"
                      ? "I accept"
                      : "நான் ஏற்றுக்கொள்கிறேன்"}
                  </Button>
                </Box>
              </Container>
              <Modal
                open={thankYouModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="thankyou-modal">
                  <div
                    className="modal-content"
                    style={{ borderRadius: "25px" }}
                  >
                    <div className="modal-body" style={{ textAlign: "center" }}>
                      <img
                        src={ThankYouIcon}
                        alt=""
                        style={{ width: "100px" }}
                      />
                      <div className="thankyou-message">
                        <Typography className="thankyou-text">
                          Thank you!
                        </Typography>
                        <Typography>
                          We appreciate you for acknowledging and accepting our
                          terms and conditions.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>
              <Box mt={5} className="footer">
                <Container pt={5}>
                  <Grid container>
                    <Grid item md={5}>
                      <Typography
                        className="community-thrives"
                        paddingTop={"20px"}
                      >
                        Every community can thrive.
                      </Typography>
                      <Typography>
                        <a
                          href="https://www.ekamillam.com/"
                          target="_blank"
                          title="Ekam Illam"
                          rel="noreferrer"
                        >
                          <img src={NivasiLightIcon} alt="footer-logo" />
                        </a>
                      </Typography>
                      <Typography className="rights-reserved">
                        <a
                          href="https://www.ekamillam.com/"
                          target="_blank"
                          title="Ekam Illam"
                          rel="noreferrer"
                        >
                          ©<span id="year">2024</span> All Rights Reserved, Ekam
                          Illam
                        </a>
                      </Typography>
                    </Grid>
                    <Grid item md={7} paddingTop={"20px"}>
                      <Box className="support-section">
                        <Typography className="support-text">
                          Support
                        </Typography>
                        <Box className="support-contents">
                          <Typography mt={4}>
                            Have questions? You can{" "}
                            <a
                              href="mailto:support@nivasi.com"
                              className="support-content"
                            >
                              get in touch
                            </a>
                            <Box mt={4}>
                              You can also text our support team at
                              <Typography>
                                <a
                                  className="support-content"
                                  href="sms:+918882134232"
                                >
                                  +91 8882134232
                                </a>
                              </Typography>
                            </Box>
                            <Box mt={4} mb={3}>
                              email us at{" "}
                              <a
                                href="mailto:support@nivasiapp.com"
                                className="support-content"
                              >
                                support@nivasiapp.com
                              </a>
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
              <img
                src={FooterImage}
                alt="footer"
                style={{ width: "100%", backgroundSize: "contain" }}
              />
            </Grid>
          </Paper>
        )
      ) : (
        <Card
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 1000,
          }}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Card>
      )}
    </React.Fragment>
  );
};

export default ServiceProviderTermsAndConditions;
