import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  openSelectLanguageModal,
  startLoading,
  stopLoading,
} from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import { fetchServiceProviderByToken } from "../../services/service-provider";
import "./ServiceProviderTermsAndConditions.scss";
import { OpenPdfViewer } from "../../helpers/OpenPdfViewer";
import SelectLanguageModal from "../Common/SelectLanguageModal";

const ServiceProviderViewAcknowlegedTerms = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [serviceProvider, setServiceProvider] = useState(null);

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const response = await fetchServiceProviderByToken(token);
      if (response.success && response.data) {
        const serviceProviderFromResponse = response?.data?.serviceProvider;
        setServiceProvider(serviceProviderFromResponse);
        dispatch(openSelectLanguageModal());
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    })();
  }, [dispatch, token]);

  const onOkHandler = async (serviceProvider, values) => {
    dispatch(startLoading());
    OpenPdfViewer("Terms & Conditions Acknowledged", serviceProvider, values);
    dispatch(stopLoading());
  };

  return (
    <React.Fragment>
      <SelectLanguageModal
        onOkHandler={(values) => {
          onOkHandler(serviceProvider, values);
        }}
        onCancelHandler={() => {}}
        cancelText="Cancel"
        okText="View"
      />
    </React.Fragment>
  );
};

export default ServiceProviderViewAcknowlegedTerms;
